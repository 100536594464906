<template>
    <div>
        <el-page-header @back="$router.go(-1)" content="周期性排班设置" />
        <el-form :model="form" :rules="rules" ref="form" label-width="100px" class="form" size="small">
            <el-form-item label="有效期" prop="start">
                <el-row style="max-width: 400px;">
                    <el-col :span="11">
                        <el-date-picker type="date" placeholder="选择日期" v-model="form.start" value-format="yyyy-MM-ddT00:00:00.0+08:00"
                            style="width: 100%;"></el-date-picker>
                    </el-col>
                    <el-col style="text-align: center;" :span="2">-</el-col>
                    <el-col :span="11">
                        <el-date-picker type="date" placeholder="选择日期" v-model="form.end" value-format="yyyy-MM-ddT00:00:00.0+08:00"
                            style="width: 100%;"></el-date-picker>
                    </el-col>
                </el-row>
            </el-form-item>
            <el-form-item label="医生" prop="doctor">
                <radio-doctor v-model="form.doctor" />
            </el-form-item>
            <el-form-item label="手术室" prop="area">
                <checkbox-area v-model="form.area" :doctor="form.doctor" />
            </el-form-item>
            <el-form-item label="出诊周期" prop="weekday">
                <el-checkbox-group v-model="form.weekday">
                    <el-checkbox :label="w.value" v-for="(w, i) in $store.state.weekList" :key="i">{{ w.name }}
                    </el-checkbox>
                </el-checkbox-group>
            </el-form-item>
            <el-form-item label="分时设置" prop="times">
                <time-inputs v-model="form.times"></time-inputs>
            </el-form-item>
            <el-form-item label="备注" prop="desc">
                <el-input type="textarea" v-model="form.desc"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitForm('form')" :loading="loading">保 存</el-button>
                <el-button @click="$router.go(-1)">返 回</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import TimeInputs from "@/components/base/timeInputs";
export default {
    components: { TimeInputs },
    watch: {
        // eslint-disable-next-line no-unused-vars
        '$route'(to, from) {
            this.getData()
            // if (to.name === 'projectAdd') {
            //     console.log(to.query)     // 在此调用函数
            // }
        }
    },
    data() {
        let validateTimes = (rule, value, callback) => {
            let result = false
            value.forEach(e => {
                if (e.time == '') result = true
                if (!e.count) result = true
            })
            if (result) {
                callback(new Error('请输入时间和数量'))
            } else {
                callback()
            }
        }
        return {
            loading: false,
            form: {
                weekday: [],
                times: []
            },
            rules: {
                start: [
                    { required: true, message: '请选择开始日期', trigger: 'blur' }
                ],
                doctor: [
                    { required: true, message: '请选择医生', trigger: 'change' }
                ],
                area: [
                    { required: true, message: '请选择手术室', trigger: 'blur' }
                ],
                weekday: [
                    { required: true, message: '请选择出诊周期', trigger: 'blur' },
                ],
                times: [
                    { required: true, message: '请输入分时设置', trigger: 'blur' },
                    { validator: validateTimes, trigger: "blur" },
                ],
            }
        };
    },
    methods: {
        async submitForm(formName) {
            const valid = await this.$refs[formName].validate().then(res => res).catch(error => error);
            if (!valid) {
                return
            }
            this.loading = true
            let resp = await this.$store.dispatch('worksheet_form', this.form)
            if (resp.data.code == 200) {
                this.$message.success("保存成功！")
                await this.$router.replace("/main/worksheet/cycle")
            } else {
                this.$message.error(resp.data.msg)
            }
            this.loading = false
        },
        async getData() {
            let id = this.$route.query.id
            if (id) {
                const resp = await this.$http.get(`/surgery/worksheet/${id}`)
                this.form = resp.data.data
            }
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style lang="scss" scoped>
.form {
    margin-top: 20px;
}
</style>
